import axios from '@/plugins/axios'

/**
 * Get categories
 */
// eslint-disable-next-line camelcase
const getCategories = async ({ type = null, company_id = null, parent_id = null, campaign_type = null }) => {
  try {
    const params = {
      type: type
    }
    // eslint-disable-next-line camelcase
    if (company_id) params.company_id = company_id
    // eslint-disable-next-line camelcase
    if (parent_id) params.parent_id = parent_id
    // eslint-disable-next-line camelcase
    if (campaign_type) params.campaign_type = campaign_type
    const response = await axios.get('categories', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getCategories
}
