<template>
  <b-modal
    id="edit-company-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hidden="reset"
    @shown="shown"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ $t('edit_company') }}</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <form @submit.prevent="submit" class="text-left default-form">
      <div
        class="my-3 alert-message-card text-color2 font-size-small px-3 py-2"
        v-if="!company.is_complete"
      >
        <p
          class="mb-0"
        >
          {{ $t('finish_company_form_warning') }}
        </p>
      </div>
      <div class="form-group mb-3">
        <label class="form-label fw-bold">{{ $t('company_name') }}*</label>
        <input type="text" class="form-control" :placeholder="$t('company_name')" v-model.trim="$v.form.name.$model" />
      </div>
      <div class="form-group mb-3">
        <label class="form-label fw-bold">{{ $t('fantasy_name') }}*</label>
        <input type="text" class="form-control" :placeholder="$t('fantasy_name')" v-model.trim="$v.form.fantasy_name.$model" />
      </div>
      <div class="form-group mb-3 mt-3">
        <label class="form-label fw-bold">{{ $t('country') }}*</label>
        <div class="custom-select">
          <select class="form-control" v-model.trim="$v.form.country.$model">
            <option disabled selected value="">{{ $t('select') }}</option>
            <option v-for="(country, index) in parsedCountries" :key="index" :value="country.code" :selected="country.code === company.country">{{ country.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-group mb-3" v-if="form.country === 'BR'">
        <label class="form-label fw-bold">{{ $t('company_personal_id') }}*</label>
        <the-mask class="form-control" :placeholder="$t('company_personal_id')+'*'" :mask="['###.###.###-##', '##.###.###/####-##']" v-model.trim="$v.form.company_document.$model" />
      </div>
      <div class="form-group mb-3" v-else>
        <label class="form-label fw-bold">{{ $t('company_tax_id') }}*</label>
        <input type="text" class="form-control" :placeholder="$t('company_tax_id')+'*'" v-model.trim="$v.form.company_document.$model" />
      </div>
      <div class="mb-3" v-if="form.country === 'BR'">
        <div class="position-relative">
          <LoaderOverlay
            size="big"
            v-if="zipcodeLoader"
          />
          <div
            class="d-lg-flex mb-3"
          >
            <div class="col-lg-4 pl-0">
              <div class="form-group">
                <label class="form-label fw-bold">{{ $t('zipcode') }}</label>
                <TheMask
                  :mask="'#####-###'"
                  v-model.trim="$v.form.address.zipcode.$model"
                  placeholder=""
                  class="form-control"
                  @change.native="getZipcode"
                />
              </div>
            </div>
            <div class="col-lg-8 px-0 ps-3">
              <div class="form-group">
                <label class="form-label fw-bold">{{ $t('street') }}</label>
                <input type="text" class="form-control" v-model.trim.trim="$v.form.address.street.$model" placeholder="" :readonly="readonlyForm" />
              </div>
            </div>
          </div>
          <div
            class="d-lg-flex mb-3"
          >
            <div class="col-lg-4 pl-0">
              <div class="form-group">
                <label class="form-label fw-bold">{{ $t('number') }}</label>
                <input type="text" class="form-control" v-model.trim.trim="$v.form.address.number.$model" placeholder="" />
              </div>
            </div>
            <div class="col-lg-4 pl-0 px-3">
              <div class="form-group">
                <label class="form-label fw-bold">{{ $t('complement') }}</label>
                <input type="text" class="form-control" v-model.trim.trim="form.address.complement" placeholder="" />
              </div>
            </div>
            <div class="col-lg-4 px-0">
              <div class="form-group">
                <label class="form-label fw-bold">{{ $t('neighborhood') }}</label>
                <input type="text" class="form-control" v-model.trim.trim="$v.form.address.neighborhood.$model" placeholder="" :readonly="readonlyForm" />
              </div>
            </div>
          </div>
          <div
            class="d-lg-flex"
          >
            <div class="col-lg-5 pl-0 pe-3">
              <div class="form-group">
                <label class="form-label fw-bold">{{ $t('city') }}*</label>
                <input type="text" class="form-control" v-model.trim.trim="$v.form.address.city.$model" placeholder="" readonly />
              </div>
            </div>
            <div class="col-lg-5 px-0 pe-3">
              <div class="form-group">
                <label class="form-label fw-bold">{{ $t('state') }}</label>
                <input type="text" class="form-control" v-model.trim.trim="$v.form.address.state.$model" placeholder="" readonly />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-3">
        <Button
          type="submit"
          class="btn btn-primary"
          :disabled="$v.$invalid"
          :loading="loader"
        >
          {{ $t('save') }}
        </Button>
      </div>
    </form>
  </b-modal>
</template>

<script>

import CategoriesService from '@/modules/categories/services/categories-service'
import CompaniesService from '@/modules/companies/services/companies-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { getZipcodeData, parseErrorResponseToArray } from '@/utils'
import { required } from 'vuelidate/lib/validators'
import variables from '@/mixins/variables'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import LoaderOverlay from '@/components/common/Loader/LoaderOverlay'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'EditCompanyModal',
  mixins: [variables],
  props: ['company'],
  components: {
    AlertWraper,
    Button,
    LoaderOverlay,
    TheMask
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      categories: [],
      categoriesLoader: false,
      zipcodeLoader: false,
      form: {
        name: '',
        fantasy_name: '',
        company_document: '',
        country: '',
        logo: '',
        address: {
          zipcode: '',
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: ''
        }
      },
      readonlyForm: true
    }
  },
  validations () {
    const obj = {
      form: {
        name: { required },
        fantasy_name: { required },
        company_document: { required },
        country: { required }
      }
    }
    if (this.form.country === 'BR') {
      obj.form.address = {
        zipcode: { required },
        street: { required },
        number: { required },
        neighborhood: { required },
        city: { required },
        state: { required }
      }
    }
    return obj
  },
  computed: {
    /**
     * Parse countries list
    */
    parsedCountries () {
      const countries = this.countries.map(item => {
        return {
          code: item,
          name: this.$t(`countries_names.${item}`)
        }
      })

      countries.sort((a, b) => a.name.localeCompare(b.name))

      return countries
    }
  },
  methods: {
    /**
     * Handle change on file
     */
    changeFile (e) {
      const file = e.target.files[0]
      this.form.logo = file
    },
    /**
     * Get categories for registration
     */
    async getCategories () {
      this.content = null
      this.categoriesLoader = true
      try {
        const categories = await CategoriesService.getCategories({ type: 'ADVERTISER' })
        this.categories = categories.data
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.categoriesLoader = false
    },
    /**
     * Get zipcode data given a zipcode
     */
    async getZipcode () {
      this.zipcodeLoader = true
      this.content = null
      try {
        const address = await getZipcodeData(this.form.address.zipcode)
        this.$v.form.address.street.$model = address.street
        this.$v.form.address.neighborhood.$model = address.neighborhood
        this.$v.form.address.city.$model = address.city
        this.$v.form.address.state.$model = address.state

        this.readonlyForm = !!address.street
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.zipcodeLoader = false
    },
    reset () {
    },
    /**
     * Set company data to form
     */
    setCompany () {
      this.form.name = this.company.name
      this.form.fantasy_name = this.company.fantasy_name
      this.form.company_document = this.company.document
      this.form.country = this.company.country
      if (this.company.address) {
        this.form.address.zipcode = this.company.address.zipcode
        this.form.address.street = this.company.address.street
        this.form.address.number = this.company.address.number
        this.form.address.complement = this.company.address.complement
        this.form.address.neighborhood = this.company.address.neighborhood
        this.form.address.city = this.company.address.city
        this.form.address.state = this.company.address.state
      }

      // If company is advertiser, get categories
      if (this.categories.length === 0) this.getCategories()
    },
    /**
     * On modal shown
     */
    shown () {
      this.setCompany()
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true
      this.content = null
      try {
        const form = Object.assign({}, this.form)
        if (form.website) form.website = `https://${form.website.replace('https://', '').replace('http://', '')}`
        await CompaniesService.updateCompany(this.company.id, form)
        const notification = {
          notification: {
            type: 'success',
            content: this.$t('company_data_updated_successfully')
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$bvModal.hide('edit-company-modal')
        this.$emit('company-updated', this.form)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
